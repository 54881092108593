import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import RequirementProductTable from "./RequirementProducts/RequirementProductTable";
import GenerateRequirementContent from './GenerateRequirementContent'; // Adjust the path as necessary
import { commonFontWeight } from '../../Helpers/ConstantProperties';

const GenerateRequirement = (props) => {
  const location = useLocation();
  var { item } = location.state || {};

  if (props.item) item = props.item

  return (
    <Paper elevation={2} sx={{ padding: 2, margin: 2 }}>
      <Grid2 container direction="column" spacing={2}>
        <Grid2 xs={12}>
          <Typography variant='h6' component="h2" sx={{ fontWeight: commonFontWeight }}>
            {item.customerName}
          </Typography>
        </Grid2>

        <Grid2 xs={12}>
          <GenerateRequirementContent item={item} />
        </Grid2>

        <Grid2 xs={12}>
          <RequirementProductTable data={item} />
        </Grid2>

      </Grid2>
    </Paper>
  );
};

export default GenerateRequirement;