import React from 'react';
import ProductHeading from './ProductHeading';
import { quotationRmTableValues } from '../../../Helpers/helpers';

const QuotationRmHeading = () => {
  return (
    <ProductHeading tableValues={quotationRmTableValues} />
  );
};

export default QuotationRmHeading;
