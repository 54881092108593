import { Typography } from "@mui/material"
import { DecodeServerError } from "../../../Helpers/helpers"
import { commonFontSize } from "../../../Helpers/ConstantProperties"

export const GenericErrorComponent = ({ error }) => {
    let errMessage = DecodeServerError(error)
    return (<Typography fontSize={commonFontSize}>{errMessage}</Typography>)
}

export const GenericSuccessComponent = ({ data, message }) => {
    let SuccessMsg = ` ${message}  with id ${data.id}`
    return (<Typography fontSize={commonFontSize}>{SuccessMsg}</Typography>)
}