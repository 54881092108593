import { Paper, Typography } from "@mui/material";
import CardActionButtons from "../GenericComponents/DataView/CardActionButtons";
import GenericSkeleton from "../GenericComponents/DataView/GenericSkeleton";
import { commonFontWeight, CREATE_IN_CHALLAN, CREATE_OUT_CHALLAN, DISPLAY_CHALLAN, INWORD_CHALLAN, UPDATE_ON_VOUCHER } from "../../Helpers/ConstantProperties";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { GenericErrorComponent, GenericSuccessComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';

function ChallanCard({ item }) {

    const successMessage = "Successfully Deleted!";

    const deleteChallan = async (token, khID) => {
        return await serviceHelpers.deleteChallans(token, khID, item.id);
    };

    const navigate = useNavigate();

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: item.type === INWORD_CHALLAN ? CREATE_IN_CHALLAN : CREATE_OUT_CHALLAN,
        queryFunction: deleteChallan,
        queryKeyValue: UPDATE_ON_VOUCHER,
    };

    if (item.showSkeleton) return <GenericSkeleton />;

    return (
        <Grid2 key={item.id} xs={12}>
            <Paper sx={{ padding: '0.3rem', minWidth: 0, px: 2 }} elevation={2} >
                <Grid2 container direction={'row'} alignItems="center" spacing={2}>
                    <Grid2 xs={12} md={4}>
                        <Typography
                            noWrap
                            variant="subtitle2"
                            sx={{
                                fontWeight: commonFontWeight,
                                paddingLeft: { xs: '0.3rem' },
                                textAlign: { xs: 'left' }
                            }}
                        >
                            {item.refranceId}
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={4}>
                        <Typography
                            noWrap
                            variant="body2"
                            sx={{
                                textAlign: { xs: 'left' },
                                paddingLeft: { xs: '0.3rem' },
                                color: 'text.secondary'
                            }}
                        >
                            {item.type}
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <CardActionButtons onEditIcon={<VisibilityIcon />} onEdit={() => { navigate(DISPLAY_CHALLAN, { state: item }) }} onDelete={onDelete} />
                    </Grid2>
                </Grid2>
            </Paper>
        </Grid2>
    );
}

export default ChallanCard;