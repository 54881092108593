import { Typography } from "@mui/material";
import { getLocalDateString } from "../../Helpers/helpers";
import NoteRoundedIcon from '@mui/icons-material/NoteRounded';
import { CLOSED_INQUIRY_TAG } from "../../Helpers/ExtraProperties";

function DashboardCardDate({ item }) {
    var date = item.tag === CLOSED_INQUIRY_TAG ? item.lastUpdated : item.followUpDate;
    return (
        <Typography
            variant="body2"
            sx={{
                textAlign: { xs: 'left', sm: 'center' }
            }}
        >
            <NoteRoundedIcon sx={{ verticalAlign: 'middle', marginRight: 1, color: 'grey.400' }} />
            {getLocalDateString(date)}
        </Typography>
    )
}

export default DashboardCardDate;