import { useLocation } from "react-router-dom";
import { NEW_ORDER, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { CreateOrderProps } from "../../Helpers/ExtraProperties";
import { GenericErrorComponent, GenericSuccessComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import { GenericEditForm } from "../GenericComponents/FormComponent/GenericEditForm";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { getInquiryCurrentData, getInquiryObject, InquiryPostDispatch } from "../../Helpers/helpers";
import InquiryProductDetails from "../Inquiry/InquiryProductDetails";

export const EditOrderComponent = () => {
    const location = useLocation();
    const successMessage = "Order Edited SuccessFully "

    const editPartyQueryFunction = async (state, token, khID) => {
        const updatedState = getInquiryObject(state);
        return await serviceHelpers.updateOrder(token, khID, updatedState, updatedState.id)
    }

    const EditProps = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: NEW_ORDER,
        queryKeyValue: UPDATE_ON_INQUIRY,
        formTitle: "Edit Order",
        propertyList: CreateOrderProps,
        currentData: getInquiryCurrentData(location.state),
        queryFunction: editPartyQueryFunction,
        afterDispatch: InquiryPostDispatch,
        GroupDetailsComponent: InquiryProductDetails,
    }

    return <GenericEditForm
        {...EditProps}
    />
}