import { Box } from "@mui/material";
import { FAIL, PASS } from "../../Helpers/ConstantProperties";
import { useState } from "react";
import GenericDialog from "../GenericComponents/Dialog/GenericDialog";
import ProductionManagerRemark from "./ProductionManagerRemark";
import { QualityCheckActions } from "./QualityCheckActions";

export const QualityActionButtons = ({ item, index }) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Box display="flex" alignItems="center">
            <QualityCheckActions
                item={item}
                text={PASS}
                qualityCheck={PASS}
                index={index}
                onCancel={()=>setDialogOpen(true)}
                cancelText={FAIL}
            />
            <GenericDialog
                title={"Production Manager Remark"}
                open={dialogOpen}
                setOpen={setDialogOpen}
                content={
                    <ProductionManagerRemark
                        product={item.products[index]}
                        ActionButton={(props) => <QualityCheckActions
                            item={item}
                            index={index}
                            {...props}
                            onCancel={() => setDialogOpen(false)}
                            cancelText={'Close'}
                            handleOnSuccess={() => setDialogOpen(false)}
                        />}
                    />
                }
            />
        </Box>
    );
};
