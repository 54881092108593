import { Button } from "@mui/material";
import { DISPATCH, PASS, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { useContext } from "react";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";

export const QualityCheckActions = ({ item, text = 'save', variant = "text", qualityCheck, mark, index, cancelText, onCancel, handleOnSuccess = () => { } }) => {
    const queryClient = useQueryClient();
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryFunction = async () => {
        var note = `${item.products[index].product.name} is Marked As ${qualityCheck}`;
        var message = mark ? note = note + ' for reason ' + mark : note;
        item.products[index].qualityCheck = qualityCheck;
        const passedProductsCount = item.products.reduce((count, product) => {
            return product.qualityCheck === PASS ? count + 1 : count;
          }, 0);

        const updatedprop = {
            products: [...item.products],
        };

        if (passedProductsCount === item.products.length) {
            message =`${message} State updated to ${DISPATCH}`
            updatedprop.status = DISPATCH;
        }
        return await serviceHelpers.updateLeadStatus(token, khID, updatedprop, item.id, message);
    };


    const { mutate, isPending } = useMutation(
        {
            mutationFn:queryFunction,

            onSuccess: () => {
                queryClient.invalidateQueries({
                    predicate: (query) => query.queryKey.includes(UPDATE_ON_INQUIRY),
                });
                handleOnSuccess();
            }
        })

    return (
        <>
            <Grid2 container spacing={1} justifyContent={'center'}>
                <Grid2>
                    <Button variant={variant} disabled={isPending} onClick={() => mutate()} color="success">
                        {text}
                    </Button>
                </Grid2>
                {onCancel && <Grid2>
                    <Button variant={variant} onClick={onCancel} color="error">
                        {cancelText}
                    </Button>
                </Grid2>}
            </Grid2>
        </>
    );
};