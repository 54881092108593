import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { NEW_ORDER, ORDER_SERIES_PREFIX } from '../../Helpers/ConstantProperties';
import CreateInquiry from '../Inquiry/CreateInquiry';
import { CreateOrderProps } from '../../Helpers/ExtraProperties';

const CreateOrder = () => {
    const queryFunction = async (token, khID, state, currentDate) => {
        if (!state.poNumber) {
            let seriesNo = await serviceHelpers.getSeriesNumber(token, khID, { prefix: ORDER_SERIES_PREFIX });
            state.poNumber = seriesNo.id
        }
        return await serviceHelpers.createOrder(token, khID, state, currentDate);
    }

    return (
        <CreateInquiry
            propertyList={CreateOrderProps}
            queryFunction={queryFunction}
            successMessage="Order created successfully"
            formTitle="Add New Order"
            navigateTo={NEW_ORDER}
        />
    )
}
export default CreateOrder
