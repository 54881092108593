import { Button, Paper } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useLocation, useNavigate } from "react-router-dom"
import { InquiryDetailBody } from "./InquiryDetailBody"
import { commonFontWeight, HOME, regularFontSize } from "../../Helpers/ConstantProperties"
import { Typography } from "@mui/material"
import InquiryProductDetails from "./InquiryProductDetails"
import InquiryContent from "./InquiryContent"
import DetailPaneWithDropdown from "../GenericComponents/Layout/DetailPaneWithDropdown"
import { createContext, useContext } from "react"
import GenerateRequirement from "../Requirement/GenerateRequirement"
import InvoicePage from "../TaxInvoice/InvoicePage"
import QuotationPage from "../Quotation/QuotationPage"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InquiryProductHeading from "./InquiryProductHeading";
import { getProductHeadingWithoutAction } from "../../Helpers/helpers"
import { FirmContext } from "../Contexts/FirmContext"

export const DetailPaneContext = createContext();

export const InquiryInformation = () => {
    const { state } = useLocation();
    const { currentFirm } = useContext(FirmContext);
    const navigate = useNavigate()
    const checkDisable = state.products?.length === 0 || !state.products
    const tabData = [
        {
            id: 0, name: "Contact Detail", Component: ({ item }) => (
                <Grid2 className="border border-1 m-1">
                    <InquiryDetailBody item={item} />
                    <InquiryProductDetails
                        allowActions={false}
                        data={item}
                        HeadingComponent={
                            (props) => <InquiryProductHeading {...props} heading={getProductHeadingWithoutAction(currentFirm.editSaleRate)} />}
                    />
                </Grid2>
            )
        },
        { id: 1, name: "Quotation", Component: (props) => <QuotationPage data={props.item} />, disabled: checkDisable },
        { id: 2, name: "Requirement & Production", Component: GenerateRequirement, disabled: checkDisable },
        { id: 3, name: "Invoice", Component: InvoicePage, disabled: checkDisable },
        { id: 4, name: "Comments", Component: InquiryContent },
    ];

    return (
        <Grid2 container direction={"column"} spacing={1}>
            <Paper elevation={1} className='m-3 p-2 w-90'>
                <Grid2 className="px-2"
                    xl={12} spacing={2} container direction='column' >
                    <Grid2 className="col-12 d-flex">
                        <Button variant="text" onClick={() => navigate(HOME)} className="d-flex gap-3">
                            <ArrowBackIcon />
                        </Button>
                        <Typography fontWeight={commonFontWeight} className="text-break align-self-center" sx={{ fontSize: regularFontSize }} >
                            {state.customerName}
                        </Typography>
                    </Grid2>
                    <DetailPaneContext.Provider value={{ disableAction: true }}>
                        <DetailPaneWithDropdown
                            item={state}
                            tabData={tabData}
                            paneID={"InquiryInformation"}
                        />
                    </DetailPaneContext.Provider>
                </Grid2>
            </Paper>
        </Grid2 >
    )
}