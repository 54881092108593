import React from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import QuotationPage from '../Quotation/QuotationPage'

const InvoicePage = ({ item }) => {
  return (
    <Grid2 container spacing={0}>
      <QuotationPage
        documentTitle="Tax Invoice"
        firmTitle="Bill From"
        clientTitle="Bill To"
        detailsTitle="Invoice Details"
        dateTitle="Invoice Date"
        idTitle="Invoice ID"
        data={item}
      />
    </Grid2>
  )
}

export default InvoicePage