import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { VIEWORDER } from '../../../Helpers/ConstantProperties';

const ViewOrder = ({ item }) => {
    const navigate = useNavigate();

    const onMenuClick = () => {
        navigate(VIEWORDER, { state: { item } });
    };

    return (
        <MenuItem onClick={onMenuClick}>
            View Order
        </MenuItem>
    );
};

export default ViewOrder;
