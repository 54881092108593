import { useContext, useState } from "react"
import { getValueInPercentOfTotal } from "../../../Helpers/helpers"
import { Button } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FirmContext } from "../../Contexts/FirmContext";
import { AuthContext } from "../../Auth/Auth";
import { GenericAlert } from "../Alerts/GenericAlert";

export const GenericBulkCreation = ({
    Total,
    BulkArray,
    onSuccess,
    message,
    title
}) => {
    const [created, setCreated] = useState(0)
    const [verify, setVerified] = useState(false)
    const [error, setError] = useState(0)
    const { currentUserObject } = useContext(AuthContext);
    const { currentFirm } = useContext(FirmContext);

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <Box
                    sx={{
                        top: 40,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="d-flex flex-column">
                        <CircularProgress variant="determinate" {...props} />
                        <Typography variant="caption" className="m-2" component="div" color="text.secondary">
                            {`${Math.round(props.value)}%`}
                        </Typography>
                    </div>
                </Box>
            </Box>
        );
    }

    const onApprove = async () => {
        setVerified(true);
        for (let index = 0; index < BulkArray.length; index++) {
            const element = BulkArray[index];
            try {
                await onSuccess(currentUserObject.accessToken, currentFirm.khID, element);
            } catch (err) {
                console.log(err);
                setError(prev => prev + 1)
            }
            setCreated(prev => prev + 1)
        }
    }
    if (error + created >= Total) {
        return (<div className="m-2">
            <GenericAlert severity={"success"} errMessage={"SuccessFully Addded !!"} />
        </div>)
    }

    if (verify) {
        return (
            <div className="d-flex flex-column p-2 gap-2">
                <ul className="list-group">
                    <div className="list-group-item">
                        {created}/{Total}{message} !!
                    </div>
                    <CircularProgressWithLabel value={getValueInPercentOfTotal((created), Total)} />
                </ul>
            </div>
        )
    }

    return (
        <div className="container m-2">
            <Button className="w-100 d-flex justify-content-center" variant="contained" type="button" onClick={onApprove}>Start Uploading</Button>
        </div>
    )
}