import React from 'react';
import { quotationRmTableValues } from '../../../Helpers/helpers';
import ProductContent from './ProductContent';

const QuotationRmContent = ({ productData }) => {
  return (
    <ProductContent productData={productData} tableValues={quotationRmTableValues} />
  );
}

export default QuotationRmContent;