import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import TextEllipsis from './TextEllipsis';
import { checkValue } from '../../Helpers/helpers';
import { commonFontWeight } from '../../Helpers/ConstantProperties';

const Label = ({ label, value }) => (
    <TextEllipsis
        text={<span><strong>{label}</strong> {value}</span>}
        variant="body2"
        noWrap={false}
    />
);

const QuotationInfo = ({ title, name, address, city, pincode, gstin, pan, phoneNumber, email }) => (
    <Grid2 container spacing={1} p={2} direction="column">
        <Grid2 xs={12}>
            <TextEllipsis
                text={title}
                variant="h6"
                component="div"
                sx={{ fontWeight: commonFontWeight, mb: 1 }}
            />
        </Grid2>
        <Grid2 xs={12}>
            <TextEllipsis
                text={name}
                color={'primary.main'}
            />
        </Grid2>
        {(address || city) && (
            <Grid2 container spacing={1} xs={12}>
                {address && pincode ? (
                    <Grid2 xs={12}>
                        <Label label="Address:" value={`${checkValue(address)}, ${checkValue(city)}, ${checkValue(pincode)}`} />
                    </Grid2>
                ) : city && (
                    <Grid2 xs={12}>
                        <Label label="City:" value={checkValue(city)} />
                    </Grid2>
                )}
            </Grid2>
        )}
        <Grid2 container spacing={1} xs={12}>
            <Grid2 xs={12}>
                <Label label="GSTIN:" value={checkValue(gstin)} />
            </Grid2>
            <Grid2 xs={12}>
                <Label label="PAN:" value={checkValue(pan)} />
            </Grid2>
            <Grid2 xs={12}>
                <Label label="Contact No:" value={phoneNumber} />
            </Grid2>
            <Grid2 xs={12}>
                <Label label="Email ID:" value={email} />
            </Grid2>
        </Grid2>
    </Grid2>
);

export default QuotationInfo;