import { useContext } from 'react';
import { UPDATE_ON_CONTACTS, CONTACTS, CREATE_CONTACTS } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataView from '../GenericComponents/DataView/DataView';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import ContactCard from './ContactCard';
import DataviewList from '../GenericComponents/DataView/DataviewList';

function Contacts() {
    const { currentUserObject, token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);


    const queryKey = [
        currentUserObject.uid,
        khID,
        UPDATE_ON_CONTACTS,
        CONTACTS,
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getContacts(token, khID, null, null, pageParam);
    }

    const getSearchableValue = (current) => {
        return (
            current.name + " "
            + current.companyName + " "
            + current.city + " "
            + current.phoneNumber + " "
            + current.email
        )
    }

    return (
        <DataView
            routeDetails={{ heading: "Contacts", subText: "Contacts" }}
            limitSupported={true}
            getSearchableValue={getSearchableValue}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: ContactCard }}
            buttonDetails={{ text: "New Contact", navigateTo: CREATE_CONTACTS }}
            searchingPlaceholder={"Search By Name, Company Name, city, phoneNumber, email"}
            DisplayComponent={DataviewList}
        />
    )
}

export default Contacts