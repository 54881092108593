import { PRODUCT_TYPE_PROCESS, PRODUCTION } from '../../Helpers/ConstantProperties';
import { DashBoardController } from '../Dashboard/DashBoardController';

const ProductionDashboard = () => {
    const filterObject = {
        status: PRODUCTION
    }

    return <DashBoardController
        filterObject={filterObject}
        customQueryKey={[PRODUCTION]}
        withBalance={true}
        productType={PRODUCT_TYPE_PROCESS}
    />
}

export default ProductionDashboard