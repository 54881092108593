import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EDITORDER } from '../../../Helpers/ConstantProperties';

const EditOrder = ({ item }) => {
    const navigate = useNavigate();

    const onMenuClick = () => {
        navigate(EDITORDER, { state: item });
    };

    return (
        <MenuItem onClick={onMenuClick}>
            Edit Order
        </MenuItem>
    );
};

export default EditOrder;
