import { useState } from "react";
import { ShowNumber } from "../../Helpers/helpers";
import GenericSkeleton from "../GenericComponents/DataView/GenericSkeleton";
import { Card, CardContent, Typography } from "@mui/material";
import GenericDialog from "../GenericComponents/Dialog/GenericDialog";
import StockCardDialogContent from "./StockCardDialogContent";

export default function StockCard({ item }) {
    const [dialogOpen, setDialogOpen] = useState(false);

    if (item.showSkeleton) return <GenericSkeleton />;

    return (
        <>
            <Card>
                <CardContent onClick={() => setDialogOpen(true)}>
                    <Typography variant="h6" component="div">
                        {item.resource.name}
                    </Typography>
                    <Typography variant="body2" textAlign={"right"}>
                        {ShowNumber(item.units, 2)}
                    </Typography>
                </CardContent>
            </Card>
            <GenericDialog
                title={`${item.resource.name} Details`}
                open={dialogOpen}
                setOpen={setDialogOpen}
                content={<StockCardDialogContent item={item} />}>
            </GenericDialog>
        </>
    )
}