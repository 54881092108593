import { Box, Typography } from "@mui/material";
import { commonFontWeight, CUSTOMER, INQUIRY_DETAIL, NOT_AVAILABLE } from "../../Helpers/ConstantProperties";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";

function DashboardCardCommonPart({ item }) {
    const navigate = useNavigate()

    var handleContactClick = () => {
        navigate(INQUIRY_DETAIL, { state: item })
    };

    const isNewCustomer = item.customerId ? '' : ' *';

    var customerName = item.customerName
        ? item.customerName + isNewCustomer
        : "New Customer";

    var cursor = 'pointer';
    if (item.type === CUSTOMER) {
        handleContactClick = () => { }
        customerName = item.name;
        cursor = 'default'
    }

    return (
        <>
            <Grid2 xs={12} sm={4} md={2.5}>
                <Box className='px-2'>
                    <Typography
                        noWrap
                        variant="subtitle2"
                        color={"primary.main"}
                        onClick={() => handleContactClick()}
                        sx={{
                            fontWeight: commonFontWeight,
                            cursor: cursor,
                            textAlign: { xs: 'left', sm: 'left' }
                        }}
                    >
                        {item.contactPerson || NOT_AVAILABLE}
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            textAlign: { xs: 'left', sm: 'left' },
                            color: 'text.secondary'
                        }}
                    >
                        {item.city}
                    </Typography>
                </Box>
            </Grid2>
            <Grid2 xs={12} sm={4} md={2}>
                <Typography
                    variant="subtitle2"
                    noWrap
                    sx={{
                        textAlign: { xs: 'left', sm: 'center' },
                        fontWeight: commonFontWeight,
                    }}
                >
                    {customerName}
                </Typography>
            </Grid2>
        </>
    )
}

export default DashboardCardCommonPart;