import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GST_RATE, HSN_CODE_PROP, productProps, propertyList } from '../../Helpers/ExtraProperties';
import { INTERNAL_PROCESS, NUMBERS, PRODUCT_STATE, PRODUCT_TYPE_PROCESS, UPDATE_ON_PRODUCT } from '../../Helpers/ConstantProperties';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';
import { useContext } from 'react';
import { FirmContext } from '../Contexts/FirmContext';
import { useLocation } from 'react-router-dom';
import { deepCopyObject, productConfig, updatePropertyListProduct } from '../../Helpers/helpers';

const CreateProduct = () => {
    const { currentFirm } = useContext(FirmContext);
    const location = useLocation();
    const productType = location.state?.type;
    const productRoute = location.state?.navigateTo;

    const queryFunction = async (state, token, khID) => {
        state.type = productType
        return await serviceHelpers.createProduct(token, khID, state)
    }

    const invalidateQueryKey = UPDATE_ON_PRODUCT;

    if (currentFirm.gstDropdownList) {
        const obj = productProps.find((element) => element.item.name === GST_RATE);
        obj.item = propertyList.gstDropdown
        obj.item.dropDownList = currentFirm.gstDropdownList;
    }

    var PropsList = productProps;

    var currentData = { unit: NUMBERS, [HSN_CODE_PROP]: currentFirm[HSN_CODE_PROP], GSTRate: currentFirm.defaultGST?.toString() }
    // gstrate has toString because of dropdown thows error if value is number
    if (productType === PRODUCT_TYPE_PROCESS) {
        PropsList = updatePropertyListProduct(deepCopyObject(productProps), PRODUCT_TYPE_PROCESS)
        currentData[PRODUCT_STATE] = INTERNAL_PROCESS
    }

    const { formTitle, successMessage, addButtonText, displayName } = productConfig[productType];

    const newFormProps = {
        formTitle,
        propertyList: PropsList,
        queryFunction,
        queryKeyValue: invalidateQueryKey,
        navigateTo: productRoute,
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText,
        enableVerify: true,
        VerifyAlertComponent: GenericVerifyComponent,
        buttonClasses: "",
        currentData: currentData,
        enableClear: true
    }
    newFormProps.propertyList[1].item.displayName = displayName;

    return (
        <GenericForm
            {...newFormProps}
        />
    )
}
export default CreateProduct
