import React, { useContext } from "react";
import { getLocalDateString, ShowNumber, sortByObjectKey } from "../../Helpers/helpers";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { INQUIRY_STORE_ACCOUNT_ID, STOCK_STMT_DETAILS } from "../../Helpers/ConstantProperties";
import { PRODUCT_TAG } from "../../Helpers/ExtraProperties";
import { useQuery } from "@tanstack/react-query";
import { Card, CircularProgress, Typography, Grid } from "@mui/material";
import { GenericErrorComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";

export default function StockCardDialogContent({ item }) {
    const { currentUserObject, token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryFunction = async () => {
        var data = await serviceHelpers.getResourceBalance(
            token,
            khID,
            {
                withBatches: true,
                resourceID: item.resourceID,
                accountID: INQUIRY_STORE_ACCOUNT_ID,
                date: Date.now(),
                tag: PRODUCT_TAG
            });

        data = data
            .map(item => {
                item.batches = sortByObjectKey(item.batches, item);
                return item;
            })

        return data
    }

    const { data, isLoading, error } = useQuery(
        {
            queryKey: [
                currentUserObject.uid,
                khID,
                STOCK_STMT_DETAILS,
                item.resourceID
            ],
            queryFn: queryFunction
        }
    );

    if (error) {
        return <GenericErrorComponent error={error}/>
    }

    if (isLoading) {
        return <CircularProgress />;
    }

    return <>
        {data && data.map((item) => Object.values(item.batches).map((value) => (
            <Card elevation={3} sx={{ mb: 1, p: 1 }} key={value.BatchObject.id}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={3}>
                        <Typography component="div">
                            {getLocalDateString(Number(value.BatchObject.id))}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography component="div">
                            {value.BatchObject.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} textAlign={{ xs: "left", sm: "right" }}>
                        <Typography>
                            {ShowNumber(value.BatchObject.rate, 2, true)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} textAlign={{ xs: "left", sm: "right" }}>
                        <Typography>
                            {ShowNumber(value.units, 2)}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        )))}
    </>;
}
