import { DESIGN, PRODUCT_TYPE_RAW, STOCK } from "../../Helpers/ConstantProperties";
import { DashBoardController } from "../Dashboard/DashBoardController";

const InquiryInStock = () => {
    const filterObject = {
        status: DESIGN,
    }

    return <DashBoardController
        filterObject={filterObject}
        customQueryKey={[STOCK]}
        withBalance={true}
        productType={PRODUCT_TYPE_RAW}
    />
}
export default InquiryInStock;
