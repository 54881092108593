import React, { useContext } from 'react';
import GenericHeader from '../GenericComponents/Header/GenericHeader';
import { CLOSED, INQ_CLOSED, INQ_ONTRACK, INQ_OPEN, INQ_OVERDUE, INQ_REJECTED, INQ_TODAY, OPEN, STATUS_OPTIONS, Unknown } from '../../Helpers/ConstantProperties';
import { useNavigate } from 'react-router-dom';
import DashboardContent from './DashboardContent';
import { Grid } from '@mui/material';
import { getDateDifferance } from '../../Helpers/helpers';
import { DateContext } from '../Contexts/DateContext';

export const defaultGroupByonCall = (data, currentDate) => {
    var group = Object.groupBy(data, ({ followUpDate, status }) => {
        var daysToFollowUp = getDateDifferance(followUpDate, currentDate);

        if (status === CLOSED) return INQ_CLOSED;

        if (!followUpDate) return Unknown;

        if (daysToFollowUp > 0) return INQ_OVERDUE;
        if (daysToFollowUp === 0) return INQ_TODAY;
        if (status === OPEN) return INQ_OPEN;

        return INQ_ONTRACK;
    })
    const unknownGroup = group[Unknown] ? group[Unknown].slice(0, 10) : [];
    group[Unknown] = unknownGroup;
    return group;
}

const Dashboard = ({ searchString,
    setSearchString,
    leads,
    routeDetails,
    CustomBody,
    CustomBodies = {},
    disableCard = {}
}) => {
    const { currentDate } = useContext(DateContext);
    const navigate = useNavigate();

    const newInqNavigate = () => {
        navigate(routeDetails?.navigateTo);
    };

    leads = defaultGroupByonCall(leads, currentDate);

    const statusCard = [
        {
            name: Unknown,
            tagColor: STATUS_OPTIONS[Unknown],
            data: leads[Unknown],
        },
        {
            name: INQ_OVERDUE,
            tagColor: STATUS_OPTIONS[INQ_OVERDUE],
            data: leads[INQ_OVERDUE],
        },
        {
            name: INQ_TODAY,
            tagColor: STATUS_OPTIONS[INQ_TODAY],
            data: leads[INQ_TODAY],
        },
        {
            name: INQ_OPEN,
            tagColor: STATUS_OPTIONS[INQ_OPEN],
            data: leads[INQ_OPEN],
        },
        {
            name: INQ_ONTRACK,
            tagColor: STATUS_OPTIONS[INQ_ONTRACK],
            data: leads[INQ_ONTRACK],
        },
        {
            name: INQ_CLOSED,
            tagColor: STATUS_OPTIONS[INQ_CLOSED],
            data: leads[INQ_CLOSED],
        },
        {
            name: INQ_REJECTED,
            tagColor: STATUS_OPTIONS[INQ_REJECTED],
            data: leads[INQ_REJECTED],
        },
    ]


    return (
        <>
            <GenericHeader
                title={routeDetails?.headerTitle}
                textFieldLabel="Search by Company Name, Contact Person, Phone Number or Source of Lead"
                buttonText={routeDetails?.buttonText}
                setSearchString={setSearchString}
                searchString={searchString}
                onButtonClick={newInqNavigate}
            />

            <Grid container rowGap={2}>
                {
                    statusCard.map((element, index) => {
                        if (disableCard[element.name]) return <React.Fragment key={index}></React.Fragment>;
                        const CustomBodyForStatus = CustomBodies[element.name] || CustomBody;
                        return (
                            <Grid key={index} item xs={12}>
                                <DashboardContent
                                    data={element.data}
                                    tagColor={element.tagColor}
                                    CustomBody={CustomBodyForStatus}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    );
};

export default Dashboard;
