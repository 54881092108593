import { Avatar, Paper, Typography, Box, Grid } from "@mui/material";
import { getInitials, stringToColor } from "../../Helpers/helpers";
import CardActionButtons from "../GenericComponents/DataView/CardActionButtons";
import GenericSkeleton from "../GenericComponents/DataView/GenericSkeleton";
import { commonFontWeight } from "../../Helpers/ConstantProperties";

function ContactCard({ item }) {
    if (item.showSkeleton) return <GenericSkeleton />;

    return (
        <Grid key={item.id} item xs={12}>
            <Paper className='' sx={{ padding: '0.3rem', minWidth: 0 }} elevation={2}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center'}}>
                            <Avatar sx={{ bgcolor: stringToColor(item.name), width: 35, height: 35, marginRight: 2, fontSize: "0.75rem"}}>
                                {getInitials(item.name)}
                            </Avatar>
                            <Box>
                                <Typography
                                    noWrap
                                    variant="subtitle2"
                                    color={"primary.main"}
                                    sx={{
                                        fontWeight: commonFontWeight,
                                        textAlign: { xs: 'left'}
                                    }}
                                >
                                    {item.name}
                                </Typography>
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{
                                        textAlign: { xs: 'left'},
                                        color: 'text.secondary'
                                    }}
                                >
                                    {item.city}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} lg={2}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                noWrap
                                variant="subtitle2"
                                sx={{
                                    fontWeight: commonFontWeight,
                                    paddingLeft: { xs: '0.3rem' },
                                    textAlign: { xs: 'left'}
                                }}
                            >
                                {item.companyName}
                            </Typography>
                            <Typography
                                noWrap
                                variant="body2"
                                sx={{
                                    textAlign: { xs: 'left'},
                                    paddingLeft: { xs: '0.3rem' },
                                    color: 'text.secondary'
                                }}
                            >
                                {item.role}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={3}>
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: { xs: 'left', sm: 'center' },
                                paddingLeft: { xs: '0.3rem' },
                                color: 'text.secondary'
                            }}
                        >
                            {item.phoneNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3.5} lg={3}>
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: { xs: 'left',  },
                                paddingLeft: { xs: '0.3rem' },
                                color: 'text.secondary'
                            }}
                        >
                            {item.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1.5} lg={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <CardActionButtons onEdit={() => {}} onDelete={() => {}} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default ContactCard;