import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { GENERATE_QUOTATION, VIEW_QUOTATION } from '../../../Helpers/ConstantProperties';

const ApplyTermsAndCondition = ({ item }) => {
    const navigate = useNavigate();

    const handleApplyTermsAndCondition = () => {
        navigate(GENERATE_QUOTATION, {
          state: { item: { ...item, source: VIEW_QUOTATION} }
        })

    }

    return (
        <MenuItem onClick={handleApplyTermsAndCondition}>Generate Quotation</MenuItem>
    );
};

export default ApplyTermsAndCondition;