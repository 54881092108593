import React from 'react';
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { commonFontSize } from "../../../Helpers/ConstantProperties";

const ProductTaxHeading = () => {
  const taxFields = [
    { label: "Tax Type" },
    { label: "Taxable Amount" },
    { label: "Tax Amount" },
  ];

  return (
    <>
      {taxFields.map(({ label }, index) => (
        <Grid2 key={index} xs={12} lg={4} md={4} display="flex" alignItems="center" justifyContent="center">
          <Typography fontSize={commonFontSize} noWrap>
           <strong>{label}</strong>
          </Typography>
        </Grid2>
      ))}
    </>
  );
};

export default ProductTaxHeading;