import React, { useContext } from 'react';
import { Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { commonFontSize, commonFontWeight, PRIMARY_COLOR } from '../../Helpers/ConstantProperties';
import ProductContentCell from './ProductContentCell';
import InquiryProductActionButtons from './InquiryProductActionButtons';
import { getProductHeadings } from '../../Helpers/helpers';
import { DetailPaneContext } from './InquiryInformation';

const SmallScreenInqProductContent = ({
    row,
    index,
    editIndex,
    handleFieldChange,
    startEditing,
    cancelEditing,
    clearField,
    allowActions,
    addButtonExtraProps,
    headingList,
    groupFieldName,
    CustomActionComponent,
    editSalerate }) => {
    const isEditing = editIndex === index;
    const headings = headingList ? headingList : getProductHeadings(addButtonExtraProps.enableAdd, editSalerate);

    const enableAction = useContext(DetailPaneContext);

    const renderContent = (label) => {
        switch (label) {
            case 'Product Name':
            case 'Name':
                return (
                    <Typography
                        component="span"
                        color={PRIMARY_COLOR}
                        sx={{ fontWeight: commonFontWeight }}
                    >
                        {row.product?.name}
                    </Typography>
                );
            case 'Product Code':
            case 'Item code':
                return row.product?.productItemcode;
            case 'Qty':
            case 'Quantity':
                return (
                    <ProductContentCell
                        xs={12}
                        md={12}
                        isEditing={isEditing}
                        value={row.units}
                        type="number"
                        onChange={(e) => handleFieldChange(e, 'units', index)}>
                        {row.units}
                    </ProductContentCell>
                );
            case 'Price/Unit':
            case 'Sale Rate':
                return (
                    <ProductContentCell
                        xs={12}
                        md={12}
                        isEditing={isEditing}
                        value={row.saleRate}
                        type="number"
                        onChange={(e) => handleFieldChange(e, 'saleRate', index)}
                    >
                        {row.saleRate}
                    </ProductContentCell>
                );

            case 'Description':
                return (
                    <ProductContentCell
                        xs={12}
                        md={12}
                        isEditing={isEditing}
                        value={row.productdescription}
                        onChange={(e) => handleFieldChange(e, 'productdescription', index)}
                    >
                        {row.productdescription}
                    </ProductContentCell>
                );
            default:
                return null;
        }
    };

    return (
        <Paper elevation={2} className="mt-3 p-2 rounded-0">
            <Grid2 container spacing={1}>
                {headings.map((heading, i) => (
                    <Grid2
                        key={i}
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                    >
                        <Typography
                            component="div"
                            fontSize={commonFontSize}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            {heading.label !== 'Actions' && heading.label !== 'Raw Material' && (
                                <Typography component="span" fontWeight={commonFontWeight} sx={{ mr: 1 }}>
                                    {heading.label}:
                                </Typography>
                            )}
                            {renderContent(heading.label)}
                        </Typography>
                    </Grid2>
                ))}
                {
                    !enableAction?.disableAction && (addButtonExtraProps.enableAdd || allowActions) && (
                        <Grid2 container justifyContent="flex-end" xs={12}>
                            {

                                CustomActionComponent ?
                                    CustomActionComponent :
                                    <InquiryProductActionButtons
                                        isEditing={isEditing}
                                        startEditing={startEditing}
                                        cancelEditing={cancelEditing}
                                        clearField={clearField}
                                        index={index}
                                        allowActions={allowActions}
                                        addButtonExtraProps={addButtonExtraProps}
                                        item={row}
                                        groupFieldName={groupFieldName}
                                    />}
                        </Grid2>
                    )}
            </Grid2>
        </Paper>
    );
};

export default SmallScreenInqProductContent;
