import { useLocation } from "react-router-dom"
import AddBankAccount from "./AddBankAccount"
import { serviceHelpers } from "../../Helpers/ServiceHelpers"

export const EditBankAccount = () => {
    const location = useLocation()

    const editBank = async (state, token, khID) => {
        return await serviceHelpers.updateAccount(token, khID, state, state.id)
    }

    return <AddBankAccount
        currentData={location.state}
        queryFunction={editBank}
        buttonText="Save"
        successMessage=" Bank Updated SuccessFully "
        formTitle="Edit Bank Detail"
    />
}