import { Box, Card, Typography } from "@mui/material";
import { commonFontWeight } from "../../Helpers/ConstantProperties";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import RejectedInquiryPieChart from "./ReasonWise/RejectedInquiryPieChart";
import InquiryWiseReport from "./InquiryWise/InquiryWiseReport";
import SourcewisePieChart from "./Sourcewise/SourcewisePieChart";
import DetailedReport from "./DetailedReport/DetailedReport";

function Reports() {
    return (
        <Box mx={3} my={1}>
            <Typography variant='h6' sx={{ fontWeight: commonFontWeight }}>
                Reports Generation
            </Typography>
            <Card elevation={3} sx={{ my: 1, p: 2 }}>
                <Grid2 container spacing={2} >
                    <Grid2 xs={12} md={5} flexGrow={1}>
                        <InquiryWiseReport />
                    </Grid2>
                    <Grid2 xs={12} md={3.5} flexGrow={1}>
                        <RejectedInquiryPieChart />
                    </Grid2>
                    <Grid2 xs={12} md={3.5} flexGrow={1}>
                        <SourcewisePieChart />
                    </Grid2>
                    <Grid2 xs={12} flexGrow={1}>
                        <DetailedReport />
                    </Grid2>
                </Grid2>
            </Card>
        </Box>
    );
}

export default Reports;