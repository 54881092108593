import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { GENERATE_INVOICE } from '../../../Helpers/ConstantProperties';

const GenerateInvoice = ({ item }) => {
    const navigate = useNavigate();

    const handleGenerateInvoice = () => {
        navigate(GENERATE_INVOICE, {
            state: { item }
        })
    }

    return (
        <MenuItem onClick={handleGenerateInvoice}>Generate Invoice</MenuItem>
    );
};

export default GenerateInvoice;