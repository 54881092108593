import { CircularProgress, Grid, Typography } from "@mui/material"
import { checkValue, DecodeServerError } from "../../Helpers/helpers"
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { GenericAlert } from "../GenericComponents/Alerts/GenericAlert";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { NO_DATA } from "../../Helpers/ExtraProperties";

export const DashboardCardRejection = ({ item, statusCategory }) => {
    const { currentUserObject, token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext)

    const { data, isLoading, error } = useQuery(
        {
            queryKey: [
                currentUserObject.uid,
                khID,
                UPDATE_ON_INQUIRY,
                item?.id
            ]
            ,
            queryFn: async () =>
                await serviceHelpers.getLeadHistory(token, khID, item?.id)
        }
    );

    if (error) return <GenericAlert error={DecodeServerError(error)} />

    if (isLoading) return <CircularProgress />

    if (!data || data.length === 0) {
        return <>{NO_DATA}</>
    }

    return (
        <>
            <Grid item xs={12} sm={4} md={2} >
                <Typography
                    variant="body2"
                    sx={{
                        textAlign: { xs: 'left', sm: 'center' },
                        color: statusCategory.color
                    }}
                >
                    {checkValue(statusCategory?.name)}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={2} >
                <Typography
                    className="text-black"
                    variant="body2"
                    sx={{
                        textAlign: { xs: 'left', sm: 'center' },
                        color: statusCategory.color
                    }}
                >
                    {item.sourceOfLead}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={3.5}>
                <Typography
                    className="text-danger text-truncate"
                    variant="body2"
                    sx={{
                        textAlign: { xs: 'left', sm: 'center' },
                    }}
                >
                    {data[data.length - 1].note}
                </Typography>
            </Grid>
        </>
    )
}