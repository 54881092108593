import { INQUIRY_REJECT_TAG, InquiryRejectionObj } from "../../Helpers/ExtraProperties";
import DashboardUpdateStatusAction from "./DashboardUpdateStatusAction";
import DashboardCardDate from "./DashboardCardDate";
import { DashboardCardRejection } from "./DashboardCardRejection";
import { DESIGN, OPEN, PRODUCT_TYPE_PROCESS, PRODUCT_TYPE_RAW, PRODUCTION, QUALITY_CHECK } from "../../Helpers/ConstantProperties";
import CurrentStatus from "./CurrentStatus";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { UserCardContent } from "../User/UserCardContent";
import { CATEGORIES } from "../../Helpers/helpers";
import StockDashboard from "../Stock/StockDashboard";
import QualityCheckContent from "../Qualikty Check/QualityCheckContent";
import { UserActionContext } from "./SummaryPage";
import { useContext } from "react";

function DashboardCardBody({ item }) {
    const { routeDetails } = useContext(UserActionContext);

    const statusCategory = CATEGORIES[item.status] ? CATEGORIES[item.status] : InquiryRejectionObj;

    if (item.tag === INQUIRY_REJECT_TAG) {
        return (
            <DashboardCardRejection
                statusCategory={statusCategory}
                item={item}
            />
        )
    }

    if ((item.status === OPEN && !item.assignee) || routeDetails?.reassignInquiry) {
        return (
            <UserCardContent
                item={item}
            />
        )
    }

    if (item.status === DESIGN) {
        item.checked = false;
        // that is for checking that it is for partial checking or for saving all at once
        return (
            <StockDashboard reqType={PRODUCT_TYPE_RAW} item={item} />
        )
    }

    if (item.status === PRODUCTION) {
        item.checked = true
        return (
            <StockDashboard reqType={PRODUCT_TYPE_PROCESS} item={item} />
        )
    }

    if (item.status === QUALITY_CHECK) {
        return (
            <QualityCheckContent item={item} />
        )
    }

    return (
        <>
            <Grid2 xs={12} sm={4} md={2}>
                <DashboardCardDate item={item} />
            </Grid2>
            <Grid2 xs={12} sm={4} md={2}>
                <CurrentStatus statusCategory={statusCategory} />
            </Grid2>
            <Grid2 xs={6} sm={4} md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <DashboardUpdateStatusAction
                    item={item}
                    statusCategory={statusCategory}
                />
            </Grid2>
        </>
    )
}

export default DashboardCardBody;
