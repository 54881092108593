import { NUMBERS, PRODUCT_TYPE_FINISHED } from "../../../Helpers/ConstantProperties"
import { NO_DATA, SchemaTypes } from "../../../Helpers/ExtraProperties"
import { serviceHelpers } from "../../../Helpers/ServiceHelpers"
import { extractNumber } from "../../../Helpers/helpers"
import { GenericFileRead } from "./GenericReader"

export const BulkProductCreation = () => {
    const ignoreColumn = ["Sl No."]
    const startHeaderIndex = 0

    const changeTo = {
        "Name": "name",
        "HSN Code": "productHSNcode",
        "Unit": "Units",
        "Category": "type",
        "Item Code": "productItemcode",
        "Tax Type": "TaxType",
        "Sale": "saleRate"
    }

    const customerDetails = {
        item: {
            displayName: "Product xlsx file",
            name: "product",
            type: SchemaTypes.file,
            required: true
        }
    }

    const createProduct = async (token, khID, product) => {
        var productObject = product //here we are storing the product at new object
        //because javascript take the properties which is not yet added
        //first

        if (
            productObject.TaxType === NO_DATA &&
            productObject.productHSNcode === NO_DATA &&
            productObject.itemCode === NO_DATA &&
            productObject.name === NO_DATA) {
            return ""
        }//that is for removing the blank spaces after reading we get
        //we are moving that code at outside because there are lots possiblities where we want the particular
        //properties needs to be present
        //do not check the property which we are assigning if that properties are not present

        product.unit = NUMBERS


        if (product.type) {
            product.type = PRODUCT_TYPE_FINISHED
        }

        if(typeof(product.Units) === "string"){
            product.Units = 0;
        }

        if (product['TaxType']) {
            product.GSTRate = 2 * extractNumber(product['TaxType'])
            delete product['TaxType']
        }
        return await serviceHelpers.createProduct(token, khID, product)
    }


    return (
        <GenericFileRead
            onSuccess={createProduct}
            changeTo={changeTo}
            propertyListObject={customerDetails}
            ignoreColumn={ignoreColumn}
            startHeaderIndex={startHeaderIndex}
            message={" Creating Products...."}
            title={"Bulk Product Upload"}
        />
    )
}