import { Box } from "@mui/material";
import SmallScreenRawMaterialContent from "./SmallScreenRawMaterialContent";
import RawMaterialContent from "./RawMaterialContent";
import React, { useId, useState } from 'react';
import { AddRequirementForm } from '../AddRequirementForm';
import { PROCESS_GROUP_NAME, processPropertyGroup, RM_GROUP_NAME, rmPropertyGroup } from '../../../Helpers/ExtraProperties';
import { ShowNumber } from "../../../Helpers/helpers";
import { PRODUCT_NAME, QTY, Units } from "../../../Helpers/ConstantProperties";

const ResponsiveRawMaterialContent = ({
    row,
    editRequirementToInq,
    rawMaterialExtraProps,
    processExtraProps,
    onClearRequirement,
    allowActions
}) => {
    const [Component, setComponent] = useState()
    const [Open, setOpen] = useState(false)

    const queryFunction = (state, item, propName) => {
        editRequirementToInq(state, item, propName)
        setOpen(false)
    }

    const editFnForRawMaterial = (state, item) => queryFunction(state, item, RM_GROUP_NAME)
    const editFnForProcess = (state, item) => queryFunction(state, item, PROCESS_GROUP_NAME)

    const RawMaterialDialogComponent = <AddRequirementForm
        {...rawMaterialExtraProps}
        newPropertyListObject={[rmPropertyGroup]}
        item={row}
        queryFunction={editFnForRawMaterial}
        state={row[RM_GROUP_NAME]}
        key={useId()}
    />

    const ProcessDialogComponent = <AddRequirementForm
        {...processExtraProps}
        newPropertyListObject={[processPropertyGroup]}
        item={row}
        queryFunction={editFnForProcess}
        state={row[PROCESS_GROUP_NAME]}
        key={useId()}
    />

    const handleDelete = () => {
        onClearRequirement(row);
    };


    const formatRawMaterials = (field, type) => {
        return row[type]?.map((material, i) => {
            switch (field) {
                case PRODUCT_NAME:
                    return <Box
                        textAlign={{ xs: 'left', sm: 'center' }}
                        sx={{ py: 0.5 }}
                        key={i}>
                        {material.product?.name}
                    </Box>;
                case Units:
                    return <Box
                        textAlign={{ xs: 'left', sm: 'center' }}
                        sx={{ py: 0.5 }}
                        key={i}>
                        {ShowNumber(material.rate, 2, true)}
                    </Box>;
                case QTY:
                    return <Box textAlign={{ xs: 'left', sm: 'center' }}
                        sx={{ py: 0.5 }}
                        key={i}>
                        {ShowNumber(material.units, 2)}
                    </Box>;
                default:
                    return null;
            }
        });
    };

    const props = {
        row: row,
        rawMaterialExtraProps: rawMaterialExtraProps,
        processExtraProps: processExtraProps,
        Component: Component,
        setComponent: setComponent,
        Open: Open,
        setOpen: setOpen,
        ProcessDialogComponent: ProcessDialogComponent,
        RawMaterialDialogComponent: RawMaterialDialogComponent,
        handleDelete: handleDelete,
        allowActions: allowActions,
        formatRawMaterials: formatRawMaterials
    }

    return (
        <>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                <SmallScreenRawMaterialContent {...props} />
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                <RawMaterialContent {...props} />
            </Box>
        </>
    )

};

export default ResponsiveRawMaterialContent;
