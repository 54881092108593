
import { Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardActionButtons from "../GenericComponents/DataView/CardActionButtons";
import GenericSkeleton from "../GenericComponents/DataView/GenericSkeleton";
import { useNavigate } from "react-router-dom";
import { GenericErrorComponent, GenericSuccessComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import { PRIMARY_COLOR, UPDATE_ON_PRODUCT } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { SMALL_SCREEN, useScreenSize } from "../../Helpers/helpers";
import ProductCardRowElement from './ProductCardRowElement';

function ProductCardComponent({ item }) {
    const navigate = useNavigate();
    const screenSize = useScreenSize();

    if (item.showSkeleton) return <GenericSkeleton />;

    let successMessage = "Product Deleted Successfully"

    const deleteProductQueryFunction = async (token, khid) => {
        return await serviceHelpers.deleteProducts(token, khid, item.id);
    }

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: "/Products",
        queryFunction: deleteProductQueryFunction,
        queryKeyValue: UPDATE_ON_PRODUCT,
    }

    const onEdit = () => {
        navigate("/EditProduct", { state: item })
    }

    const showCard = screenSize === SMALL_SCREEN ? true : false

    return (
        <Paper key={item.id} elevation={1}>
            <Grid2 container>
                <Grid2
                    display={showCard ? "block" : "none"}
                    xs={12}
                    alignSelf={"center"}
                    lg={2}>
                    <CardActionButtons onEdit={onEdit} onDelete={onDelete} />
                </Grid2>
                <Grid2
                    sx={{ textAlign: { color: PRIMARY_COLOR, xs: "start", md: "start" } }}
                    xs={12}
                    alignSelf={"center"}
                    lg={2.5} px={2}>
                    <Typography noWrap>
                        {item.name}
                    </Typography>
                </Grid2>

                <Grid2
                    sx={{ textAlign: { xs: "start", md: "center" } }}
                    xs={12}
                    alignSelf={"center"}
                    lg={2.5} px={2}>
                    <ProductCardRowElement
                        name={"GST Rate :"}
                        value={" " + item.GSTRate + "%"}
                    />
                </Grid2>

                <Grid2
                    sx={{ textAlign: { xs: "start", md: "center" } }}
                    xs={12}
                    alignSelf={"center"}
                    lg={3} px={2}>
                    <ProductCardRowElement
                        name={"Sale Rate :"}
                        value={` ${parseFloat(item.saleRate).toFixed(2)}`}
                    />
                </Grid2>

                <Grid2
                    sx={{ textAlign: { xs: "start", md: "center" } }}
                    xs={12}
                    alignSelf={"center"}
                    lg={3} px={2}>
                    <ProductCardRowElement
                        name={" Unit : "}
                        value={"  " + item.unit}
                    />
                </Grid2>

                <Grid2
                    display={showCard ? "none" : "block"}
                    xs={12}
                    alignSelf={"center"}
                    lg={1}>
                    <CardActionButtons onEdit={onEdit} onDelete={onDelete} />
                </Grid2>

            </Grid2>
        </Paper>
    )
}

export default ProductCardComponent;