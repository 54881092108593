import React, { useId } from 'react';
import { AddRequirementForm } from '../AddRequirementForm';
import { RequirementAction } from '../RequirementAction';
import ResponsiveInquiryProductContent from '../../Inquiry/ResponsiveInquiryProductContent';
import { PROCESS_GROUP_NAME, processPropertyGroup, RM_GROUP_NAME, rmPropertyGroup } from '../../../Helpers/ExtraProperties';

const RequirementResponsiveProductContent = ({
    startEditing,
    cancelEditing,
    clearField,
    allowActions,
    groupFieldName,
    row,
    index,
    editIndex,
    handleFieldChange,
    addButtonExtraProps,
    headingList,
    setOpen,
    setComponent,
    rawMaterialExtraProps,
    processExtraProps,
    editRequirementToInq,
    editSalerate
}) => {

    const addRawMaterial =
        (state, product) => editRequirementToInq(state, product, RM_GROUP_NAME);
    const addProcesses =
        (state, product) => editRequirementToInq(state, product, PROCESS_GROUP_NAME);

    const RawMaterialComponent = <AddRequirementForm
        {...rawMaterialExtraProps}
        newPropertyListObject={[rmPropertyGroup]}
        item={row}
        queryFunction={addRawMaterial}
        state={row[RM_GROUP_NAME]}
        key={useId()}
    />

    const ProcessComponent = <AddRequirementForm
        {...processExtraProps}
        newPropertyListObject={[processPropertyGroup]}
        item={row}
        queryFunction={addProcesses}
        state={row[PROCESS_GROUP_NAME]}
        key={useId()}
    />

    const ActionComponent = <RequirementAction
        ProcessComponent={ProcessComponent}
        RawMaterialComponent={RawMaterialComponent}
        setOpen={setOpen}
        setComponent={setComponent}
        rawMaterialExtraProps={rawMaterialExtraProps}
        processExtraProps={processExtraProps}
    />

    return (
        <ResponsiveInquiryProductContent
            row={row}
            index={index}
            editIndex={editIndex}
            handleFieldChange={handleFieldChange}
            startEditing={startEditing}
            cancelEditing={cancelEditing}
            clearField={clearField}
            allowActions={allowActions}
            addButtonExtraProps={addButtonExtraProps}
            headingList={headingList}
            groupFieldName={groupFieldName}
            CustomActionComponent={ActionComponent} 
            editSalerate={editSalerate}/>
    );
};

export default RequirementResponsiveProductContent;
