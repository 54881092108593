import React, { useContext } from "react";
import { MenuItem } from "@mui/material";
import InquiryUpdate from "../../Inquiry/InquiryUpdate";
import { addDaysToToday, CATEGORIES, getInquiryStatusArray } from "../../../Helpers/helpers";
import { DateContext } from "../../Contexts/DateContext";
import { CLOSED, CONVERTED, FIFTEEN_DAYS, FIVE_DAYS } from "../../../Helpers/ConstantProperties";

function UpdateStatus({ item, handleMenuItemClick, handleClose }) {
    const { currentDate } = useContext(DateContext);

    const queryFunction = (state) => {
        let days = FIVE_DAYS;
        let trackForCustomer = false;

        if (state.status === CONVERTED) trackForCustomer = true

        if (state.status === CLOSED) {
            days = FIFTEEN_DAYS;
            trackForCustomer = true
        }

        return {
            followUpDate: addDaysToToday(2, currentDate),
            status: state.status,
            trackForCustomer: trackForCustomer,
            days: days
        }
    }

    const successMessage = (data) => `Inquiry status updated to ${CATEGORIES[item.status].name} with id ${data.id}`

    const OptionalLabel = (option) => `${option.label}`

    const formName = " Update Status"

    const defaultValue = {
        status: item.status,
        label: CATEGORIES[item.status].name,
        message: `State updated to ${CATEGORIES[item.status].name}`,
        showCommentBox: true,
    }


    const contents = (
        <InquiryUpdate
            OptionalLabel={OptionalLabel}
            successMessage={successMessage}
            item={item}
            formName={formName}
            mutationFunction={queryFunction}
            dropDownList={getInquiryStatusArray}
            currentDropDownValue={defaultValue}
            handleClose={handleClose}
        />
    )

    const onClick = () => handleMenuItemClick(contents, "Update Status")

    return (
        <>
            <MenuItem onClick={onClick}>Change Status</MenuItem>
        </>
    );
}

export default UpdateStatus;