import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { EDIT_INQUIRY } from '../../../Helpers/ConstantProperties';

const EditInquiryAction = ({ handleClose, item }) => {
    const navigate = useNavigate()

    const handleEditInquiry = () => {
        // Add your edit inquiry logic here
        handleClose();
        navigate(EDIT_INQUIRY, { state: item })
    };

    return (
        <MenuItem onClick={handleEditInquiry}>Edit Inquiry</MenuItem>
    );
};

export default EditInquiryAction;