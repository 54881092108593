import React from 'react';
import { Paper, Typography, Box } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ShowNumber } from '../../Helpers/helpers';
import { useScreenSize, SMALL_SCREEN, MEDIUM_SCREEN } from '../../Helpers/helpers';
import { commonFontWeight } from '../../Helpers/ConstantProperties';

const TotalsRow = ({
  commonFontSize,
  totalQuantity,
  totalTaxableAmount,
  totalTax,
  totalAmountAfterTax,
}) => {
  const screenSize = useScreenSize();
  const isResponsive = screenSize === SMALL_SCREEN || screenSize === MEDIUM_SCREEN;

  const data = [
    { label: 'Total Quantity', value: `${totalQuantity}` },
    { label: 'Total Taxable Amount', value: ShowNumber(totalTaxableAmount, 2, true) },
    { label: 'Total Tax', value: ShowNumber(totalTax, 2, true) },
    { label: 'Total Amount After Tax', value: ShowNumber(totalAmountAfterTax, 2, true) }
  ];

  return (
    <Paper elevation={0} className={'px-0 py-2 rounded-0 bg-light'} m={0} p={0}>
      <Grid2 container direction={isResponsive ? 'column' : 'row'} spacing={1}>
        {isResponsive ? (
          data.map((item, index) => (
            <Grid2 key={index} xs={12} sx={{ mb: 1 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
                <Typography fontSize={commonFontSize} sx={{ fontWeight: commonFontWeight }}>
                  {item.label}:
                </Typography>
                <Typography fontSize={commonFontSize}>
                  {item.value}
                </Typography>
              </Box>
            </Grid2>
          ))
        ) : (
          [
            { xs: 2, md: 1, content: '' },
            { xs: 2, md: 1.5, content: '' },
            { xs: 2, md: 1, content: 'Total', fontWeight: commonFontWeight },
            { xs: 4, md: 1, content: `${totalQuantity}`, fontWeight: commonFontWeight },
            { xs: 2, md: 1, content: '' },
            { xs: 4, md: 1.5, content: '' },
            { xs: 4, md: 1.5, content: ShowNumber(totalTaxableAmount, 2, true), fontWeight: commonFontWeight, textAlign: 'center' },
            { xs: 2, md: 1.5, content: ShowNumber(totalTax, 2, true), fontWeight: commonFontWeight,textAlign:'center'  },
            { xs: 4, md: 1.5, content: ShowNumber(totalAmountAfterTax, 2, true), fontWeight: commonFontWeight, textAlign:'right'},
          ].map((item, index) => (
            <Grid2 key={index} xs={item.xs} md={item.md}>
              <Typography fontSize={commonFontSize} sx={{ fontWeight: item.fontWeight }} textAlign={item.textAlign || 'center'}>
                {item.content}
              </Typography>
            </Grid2>
          ))
        )}
      </Grid2>
    </Paper>
  );
};

export default TotalsRow;