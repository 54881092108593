import { UPDATE_ON_CUSTOMER } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import PartyDropDown from "../Party/PartyDropDown";

function CustomerDropDown({ getSelected, attributes, currentValue, props }) {
    return <PartyDropDown
        getSelected={getSelected}
        attributes={attributes}
        currentValue={currentValue}
        props={props}
        updateKey={UPDATE_ON_CUSTOMER}
        getFn={serviceHelpers.getCustomers}
        freeSolo={true}
        setInputValue={getSelected} />
}

export default CustomerDropDown;
