import { useLocation } from "react-router-dom";
import { HOME, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { getPropsWithProductList, InquiryProps } from "../../Helpers/ExtraProperties";
import { GenericErrorComponent, GenericSuccessComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import { GenericEditForm } from "../GenericComponents/FormComponent/GenericEditForm";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { getInquiryCurrentData, getInquiryObject, InquiryPostDispatch } from "../../Helpers/helpers";
import InquiryProductDetails from "./InquiryProductDetails";
import { FirmContext } from "../Contexts/FirmContext";
import { useContext } from "react";

export const EditInquiry = () => {
    const location = useLocation();
    const { currentFirm } = useContext(FirmContext);
    const successMessage = "Inquiry Edited SuccessFully "

    const editInquiryFunction = async (state, token, khID) => {
        const updatedState = getInquiryObject(state);
        const response = await serviceHelpers.updateInquiry(token, khID, updatedState, updatedState.id)
        await serviceHelpers.createCustomProducts(token, khID, state.products, response);
        return response;
    }

    const EditProps = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: HOME,
        queryKeyValue: UPDATE_ON_INQUIRY,
        formTitle: "Edit Inquiry",
        propertyList: getPropsWithProductList(InquiryProps, currentFirm.editSaleRate),
        currentData: getInquiryCurrentData(location.state),
        queryFunction: editInquiryFunction,
        afterDispatch: InquiryPostDispatch,
        GroupDetailsComponent: InquiryProductDetails,
    }

    return <GenericEditForm
        {...EditProps}
    />
}