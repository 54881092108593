import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { CREATE_OUT_CHALLAN } from '../../../Helpers/ConstantProperties';

const GenerateOutwordChallan = ({ item }) => {
    const navigate = useNavigate();

    const handleOutwordChallan = () => {
        navigate(CREATE_OUT_CHALLAN, {
            state: item
        })
    }

    return (
        <MenuItem onClick={handleOutwordChallan}>Generate Challan</MenuItem>
    );
};

export default GenerateOutwordChallan;