import React, { useState } from 'react';
import { Typography, Chip } from '@mui/material';
import GenericDialog from '../Dialog/GenericDialog';
import { commonFontSize } from '../../../Helpers/ConstantProperties';

const GenericProductList = ({ products }) => {
  const [showProductsDialog, setShowProductsDialog] = useState(false);

  const handleProductsDialogOpen = () => {
    setShowProductsDialog(true);
  };

  if (products.length === 0) {
    return <Typography fontSize={commonFontSize}>No Product Available</Typography>;
  }

  if (products.length === 1) {
    return (
      <Typography fontSize={commonFontSize}>
        {products[0].product.name}
      </Typography>
    );
  }

  return (
    <>
      <Typography
        fontSize={commonFontSize}
        sx={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
        onClick={handleProductsDialogOpen}
      >
        {products[0].product.name}
      </Typography>
      <Chip
        label={`+${products.length - 1}`}
        variant="contained"
        color="primary"
        size="small"
        sx={{ marginLeft: 1 }}
      />
      <GenericDialog
        open={showProductsDialog}
        setOpen={setShowProductsDialog}
        title="All Products"
        content={
          <>
            {products.map((productObj, index) => (
              <Typography key={index} fontSize={commonFontSize} py={0.5} textAlign={'left'}>
                {productObj.product.name}
              </Typography>
            ))}
          </>
        }
      />
    </>
  );
};

export default GenericProductList;
