import { Divider, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { commonFontWeight, largeFontSize, NEW_ORDER, PRIMARY_COLOR } from '../../Helpers/ConstantProperties';
import ViewOrderContent from './ViewOrderContent';
import { RequirementProductTableContent } from '../Requirement/RequirementProductTableContent';

const ViewOrderComponent = () => {
    const location = useLocation();
    const { item } = location.state || {};

    const OrderView = (props) => <RequirementProductTableContent {...props} navigateOnCancel={NEW_ORDER} />

    return (
        <Grid2 container direction={"column"} spacing={1}>
            <Paper elevation={1} className='m-3 p-2 w-90'>
                <Grid2 xs={12}>
                    <Typography py={2} color={PRIMARY_COLOR} fontWeight={commonFontWeight} fontSize={largeFontSize}>
                        {item.customerName}
                    </Typography>
                </Grid2>
                <Grid2 xs={12}>
                    <ViewOrderContent item={item} />
                </Grid2>

                <Divider className='rounded' sx={{ borderBottomWidth: 2, bgcolor: 'black', mt: 1 }} />

                <Grid2 xs={12}>
                    <Typography py={2} fontWeight={commonFontWeight} fontSize={largeFontSize}>
                        Ordered Products :
                    </Typography>
                </Grid2>
                <Grid2 xs={12}>
                    <OrderView data={item} />
                </Grid2>
            </Paper>
        </Grid2>
    );
};

export default ViewOrderComponent;
