import { useContext } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import RequirementProductTable from "./RequirementProducts/RequirementProductTable";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { INQUIRY_PRODUCTS, INQUIRY_RAW_MATERIALS, PRODUCT_TYPE_RAW, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { GenericErrorComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import { processPropertyGroup, rmPropertyGroup } from "../../Helpers/ExtraProperties";

export const RequirementProductTableContent = ({ data, navigateOnCancel }) => {
    const { currentUserObject, token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const { data: productRawMaterial, isPending, error } = useQuery(
        {
            queryKey: [
                currentUserObject.uid,
                khID,
                UPDATE_ON_INQUIRY,
                INQUIRY_PRODUCTS,
                data.id,
                INQUIRY_RAW_MATERIALS,
            ],
            queryFn: async () => await serviceHelpers.getProductWithBatches(token, khID, data.id)
        }
    );


    if (isPending) {
        return <CircularProgress />
    }

    if (error && error !== "") {
        return <GenericErrorComponent error={error} />
    }

    const rawMaterialExtraProps = {
        successMessage: "Raw Material Added",
        updatePropertyListObject: rmPropertyGroup,
        dialogTitle: "Add Raw Material ",
    }

    const processExtraProps = {
        successMessage: "Process Material Added",
        updatePropertyListObject: processPropertyGroup,
        dialogTitle: "Add Processes ",
    }

    return (
        <RequirementProductTable
            navigateOnCancel={navigateOnCancel}
            rawMaterial={productRawMaterial}
            data={data}
            successMessage={"Raw Material And Processes Added Successfully"}
            materialType={PRODUCT_TYPE_RAW}
            rawMaterialExtraProps={rawMaterialExtraProps}
            processExtraProps={processExtraProps}

        />
    )

}
