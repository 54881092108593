import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addDaysToToday, DecodeServerError, getCommentForCustomer } from "../../Helpers/helpers";
import { CUSTOMER_STATUS, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DashBoardInquiryDetail } from "../Dashboard/DashboardMenuOptions/DashBoardInquiryDetail";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { DateContext } from "../Contexts/DateContext";
import { InquiryUpdateBody } from "./InquiryUpdateBody";
import { useSnackbar } from "../Contexts/SnackbarProvider";

function InquiryUpdate({
    OptionalLabel,
    successMessage,
    item,
    formName = undefined,
    dropDownList,
    mutationFunction,
    currentDropDownValue = undefined,
    selectedDate = undefined,
    setSelectedDate = undefined,
    handleClose,
    DashBoardInquiryHeader = DashBoardInquiryDetail
}) {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const [reqStatus, setReqStatus] = useState(undefined)
    const [state, setReason] = useState(currentDropDownValue ? currentDropDownValue : null);
    const { currentDate } = useContext(DateContext);
    const { showSnackbar } = useSnackbar()


    const queryFunction = async () => {
        let update = mutationFunction(state)
        let days = undefined;
        let trackForCustomer = update.trackForCustomer

        if (trackForCustomer) days = update.days;

        if (item.customerId && trackForCustomer) {
            await serviceHelpers.updateCustomer(
                token,
                khID,
                { followUpDate: addDaysToToday(days, currentDate) },
                item.customerId,
                getCommentForCustomer(item.customerId, days)
                , currentDate
            );
        }
        delete update.days;
        delete update.trackForCustomer

        return await serviceHelpers.updateLeadStatus(
            token,
            khID,
            update,
            item.id,
            state.message,
            currentDate
        );

    }

    const queryClient = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: queryFunction,

        onSuccess: (data) => {
            setReqStatus({
                severity: "success",
                message: successMessage(data)
            })
            showSnackbar(successMessage(data), "success");
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey.includes(UPDATE_ON_INQUIRY) || query.queryHash.includes(CUSTOMER_STATUS),
            });
            handleClose()
        },

        onError: (error) => {
            setReqStatus({
                severity: "error",
                message: DecodeServerError(error),
            })
            showSnackbar(DecodeServerError(error), "error");
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            mutate();
        } else {
            form.classList.add("was-validated");
        }
    }


    const defaultDashBoardParameter = {
        setComment: setReason,
        comment: state,
        dropDownList: dropDownList,
        getOptionLabel: OptionalLabel,
        currentValue: state
    }


    if (formName) {
        defaultDashBoardParameter.formName = formName
    }

    if (setSelectedDate) {
        defaultDashBoardParameter.setSelectedDate = setSelectedDate;
        defaultDashBoardParameter.selectedDate = selectedDate;
    }

    return (
        <Grid2 container spacing={1} padding={1}>
            <DashBoardInquiryHeader item={item} />
            <InquiryUpdateBody
                defaultDashBoardParameter={defaultDashBoardParameter}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                reqStatus={reqStatus}
            />
        </Grid2>
    );
}

export default InquiryUpdate;