import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import InquiryProductDetails from '../../Inquiry/InquiryProductDetails';
import RawMaterialHeading from './RawMaterialHeading';
import ResponsiveRawMaterialContent from './ResponsiveRawMaterialContent';
import { Typography } from '@mui/material';
import { commonFontWeight } from '../../../Helpers/ConstantProperties';

const RequirementRawMaterial = ({
  data,
  editRequirementToInq,
  onClearRequirement,
  rawMaterialExtraProps,
  processExtraProps }) => {

  return (
    <Grid2 container spacing={1}>

      {
        data?.products?.length > 0 && <Grid2 xs={12}>
          <Typography variant="subtitle1" fontWeight={commonFontWeight} py={2}>
            Product List with Raw Material required :
          </Typography>
        </Grid2>
      }

      <InquiryProductDetails
        data={data}
        allowActions={true}
        HeadingComponent={RawMaterialHeading}
        ResponsiveContentComponent={(props) => <ResponsiveRawMaterialContent
          {...props}
          onClearRequirement={onClearRequirement}
          editRequirementToInq={editRequirementToInq}
          rawMaterialExtraProps={rawMaterialExtraProps}
          processExtraProps={processExtraProps} />}
      />
    </Grid2>
  );
};

export default RequirementRawMaterial;
