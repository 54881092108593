import { useContext } from "react";
import { FirmContext } from "../Contexts/FirmContext";
import { Card, CardHeader, CardContent, CardActions, Button, Typography } from "@mui/material";

function FirmCard({ item, hideButton }) {
    const { setCurrentFirm } = useContext(FirmContext);

    const handleConnectClick = () => {
        setCurrentFirm(item);
    };

    return (
        <Card>
            <CardHeader
                className="bg-primary-subtle rounded"
                title={item.name}
            />
            <CardContent className="d-flex flex-column">
                <Typography variant="h5" className="text-primary" component="div">
                    Firm ID: {item.khID}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    className="mb-3 text-secondary"
                >
                    Please give this ID to clients for joining the firm.
                </Typography>
                <Typography variant="caption">
                    You have the following access to the Firm:
                </Typography>
                <ul className="list-group">
                    {item.currentAccess.map((level, index) => (
                        <li key={index} className="list-group-item">
                            {level.name}
                        </li>
                    ))}
                </ul>
            </CardContent>
            {!hideButton && (
                <CardActions className="d-flex justify-content-center">
                    <Button
                        onClick={handleConnectClick}
                        variant="outlined"
                        color="primary"
                        size="small"
                    >
                        Connect Now
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}

export default FirmCard;