import React from 'react';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { commonFontSize } from '../../../Helpers/ConstantProperties';

const HeadingCard = ({ title }) => {
  return (
    <Grid2 container direction="column" className='bg-primary-subtle rounded' spacing={0} sx={{ height: '100%', width: '100%', paddingY: 1 }}>
      <Grid2 xs={12} display="flex" alignItems="center" justifyContent="center">
        <Typography fontSize={commonFontSize} variant="subtitle1" component="h2" noWrap>
         <strong>{title}</strong>
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default HeadingCard;