import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { CreateContact } from '../../Helpers/ExtraProperties';
import { NEW_CONTACTS, UPDATE_ON_CONTACTS } from '../../Helpers/ConstantProperties';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';

const CreateContactForm = () => {
    const successMessage = "Contact Added Successfully";

    const queryFunction = async (state, token, khID) => {
        if (state.CustomerDropDown) {
            state.customerId = state.CustomerDropDown.id;
            state.customerName = state.CustomerDropDown.name;
            delete state.CustomerDropDown;
        }
        return await serviceHelpers.createContact(token, khID, state);
    };

    const invalidateQueryKey = UPDATE_ON_CONTACTS;

    const newFormProps = {
        formTitle: "Add Contact",
        propertyList: CreateContact,
        queryFunction: queryFunction,
        queryKeyValue: invalidateQueryKey,
        navigateTo: NEW_CONTACTS,
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText: "Add",
        enableVerify: true,
        VerifyAlertComponent: GenericVerifyComponent,
        buttonClasses: "",
        currentData: {},
        enableClear: true
    };

    return (
        <GenericForm
            {...newFormProps}
        />
    );
};

export default CreateContactForm;