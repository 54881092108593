import React, { useContext } from 'react';
import { Box } from '@mui/material';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from 'react-router-dom';
import { CREATE_USER, CREATEFIRM, HOME, PRIMARY_COLOR, SECONDARY_COLOR } from '../../Helpers/ConstantProperties';
import { FirmContext } from '../Contexts/FirmContext';
import FirmBox from './FirmBox';
import { LARGE_SCREEN, useScreenSize } from '../../Helpers/helpers';
import FirmCard from './FirmCard';

const JoinFirmMessage = () => {
  const website = "https://www.edhaasvisuals.com";
  const contactNumber = "+91-9175009033";
  const { firms, setCurrentFirm } = useContext(FirmContext);
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: screenSize !== LARGE_SCREEN ? 'auto' : 'hidden',
      }}
    >
      <Grid2 
        container 
        spacing={4} 
        justifyContent="center" 
        sx={{ width: 'auto', maxHeight: '100%' }}
      >
        <Grid2 xs={12}>
          <Grid2 container spacing={2} justifyContent="center">
            {firms.map((firm, index) => (
              <Grid2 key={firm.khID} xs={12} sm={6} md={4} lg={3}>
                <FirmBox
                  firm={firm}
                  index={index}
                  onClick={() => {
                    setCurrentFirm(firm);
                    navigate(HOME);
                  }}
                />
              </Grid2>
            ))}
          </Grid2>
        </Grid2>

        <Grid2 container xs={10} sm={8} direction="row" spacing={2}>
          <Grid2 sm={6} sx={{ display: 'flex', justifyContent: 'center' }} width={'100%'}>
            <FirmCard
              title="Create Firm"
              description="New Here.. Own a Business? Create Firm"
              website={website}
              contactNumber={contactNumber}
              navigateTo={() => navigate(CREATEFIRM)}
              PRIMARY_COLOR={PRIMARY_COLOR}
              SECONDARY_COLOR={SECONDARY_COLOR}
            />
          </Grid2>
          <Grid2 sm={6} sx={{ display: 'flex', justifyContent: 'center' }} width={'100%'}>
            <FirmCard
              title="Join Firm"
              description="Please contact Edhaas Visuals:"
              website={website}
              contactNumber={contactNumber}
              navigateTo={() => navigate(CREATE_USER)}
              PRIMARY_COLOR={PRIMARY_COLOR}
              SECONDARY_COLOR={SECONDARY_COLOR}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default JoinFirmMessage;